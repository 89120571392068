import errorTemplate from "../views/404.html";
import contractsTemplate from "../views/customer-contracts.html";
import customersTemplate from "../views/customers.html";
import homeTemplate from "../views/home.html";
import calculatorTemplate from "../views/payment-calculator.html";
import customerTemplate from "../views/login/customer.html";
import merchantTemplate from "../views/login/merchant.html";
import loginTemplate from "../views/login/index.html";

const route = (event) => {
  event = event || window.event;
  event.preventDefault();
  window.history.pushState({}, "", event.currentTarget.href);
  handleLocation();
};

const routes = {
  404: errorTemplate,
  "/": homeTemplate,
  "/payment-calculator": calculatorTemplate,
  "/customers": customersTemplate,
  "/customer-contract": contractsTemplate,
  "/login": loginTemplate,
  "/login/customer": customerTemplate,
  "/login/merchant": merchantTemplate,
};

const settingEnvBanner = () => {
  const currentENV = process.env.ENVIRONMENT;
  if (currentENV === "PROD") {
    return;
  }
  const ribbonColor = {
    STG: "seconday",
    QA: "warning",
    DEV: "primary",
  };
  const envSpan = document.createElement("span");
  const envText = document.createTextNode(currentENV);
  const envDiv = document.getElementById("env-ribbon");
  envSpan.appendChild(envText);
  envDiv.append(envSpan);
  envDiv.classList.add(`ribbon-${ribbonColor[currentENV]}`);
};

settingEnvBanner();

const setFormAction = () => {
  const currentPortal = process.env.URL_PORTAL;
  const merchantForm = document.getElementById("merchant-portal");
  const customerForm = document.getElementById("customer-portal");
  if (merchantForm) {
    merchantForm.action = `https://${currentPortal}/merchant-login/`;
  }
  if (customerForm) {
    customerForm.action = `https://${currentPortal}/customer-portal/`;
  }
};
const handleLocation = async () => {
  const { hostname, pathname } = window.location;
  const { BASE_URL } = process.env;
  const isBetaOrTest = /beta|test/.test(hostname);
  const isCouponOrApply = /coupon|apply/.test(pathname);
  if (isCouponOrApply) {
    const BASEURL = isBetaOrTest ? hostname : BASE_URL;
    const redirect = `${BASEURL}${pathname}`;
    window.location.replace(redirect);
  } else {
    const absolutePath =
      pathname.length === 1 ? pathname : pathname.replace(/\/+$/, "");
    const template = routes[absolutePath] || routes[404];
    const html = await fetch(template).then((data) => data.text());
    document.getElementById("main-page").innerHTML = html;
    window.scrollTo({ top: 0 });

    setFormAction();
  }
};

window.onpopstate = handleLocation;
window.route = route;
handleLocation();

function round(x) {
  return Math.round(x * 100) / 100;
}

function calculatePayment(principal, interest, payments) {
  var returnValue = "";
  var x = 0;
  var af = 0;
  var raf = 0;
  var monthly = 0;
  var interest_free_months = 0;
  if (arguments.length >= 4) {
    interest_free_months = arguments[3];
  }
  if (interest > 0) {
    x = Math.pow(1 + interest, payments);
    af = (x * interest) / (x - 1);
    raf = af / (1 + interest_free_months * af);
    monthly = principal * raf;
  } else {
    monthly = principal / payments;
  }

  if (
    !isNaN(monthly) &&
    monthly != Number.POSITIVE_INFINITY &&
    monthly != Number.NEGATIVE_INFINITY
  ) {
    returnValue = round(monthly);
  }
  return returnValue;
}

const calculate = () => {
  var doValidation = true;
  var doCalculation = true;
  var $type = $("#payment-calculator-modal-type");
  var type = $type.val();
  if (type === undefined) {
    type = "";
  } else {
    type = "-" + type;
  }
  var plus_rent =
    $("#is_rental_y").parent().parent().data("steinway") &&
    $("#is_rental_y").prop("checked");
  var principal = $("#payment-calculator-modal-amount-financed").val();
  if (principal) {
    if (principal !== "") {
      principal = numeral().unformat(principal);
    } else {
      principal = 0;
    }
    if (doValidation) {
      if (principal == 0) {
        doCalculation = false;
        $("#payment-calculator-modal-amount-financed")
          .parents(".form-group")
          .addClass("has-error");
      } else {
        $("#payment-calculator-modal-amount-financed")
          .parents(".form-group")
          .removeClass("has-error");
      }
    }
  } else if (doValidation) {
    doCalculation = false;
    $("#payment-calculator-modal-amount-financed")
      .parents(".form-group")
      .addClass("has-error");
  }
  var $interest = $("#payment-calculator-modal-apr");
  var interest = $interest.val();
  if (typeof $interest !== "undefined") {
    if (typeof interest !== "undefined" && interest !== "") {
      interest = numeral().unformat(interest);
    } else {
      interest = 0;
    }
    interest = interest / 100 / 12;
  }
  var doInterest = false;
  if ($interest.length === 0 || typeof interest === "undefined") {
    doInterest = true;
  } else if (interest) {
    if (doValidation) {
      if (interest > 0) {
        $("#payment-calculator-modal-apr")
          .parents(".form-group")
          .removeClass("has-error");
      } else {
        doCalculation = false;
        $("#payment-calculator-modal-apr")
          .parents(".form-group")
          .addClass("has-error");
      }
    }
  } else if (doValidation) {
    doCalculation = false;
    $("#payment-calculator-modal-apr")
      .parents(".form-group")
      .addClass("has-error");
  }
  var month_rows = $("#payment-calculator-modal-months" + type).children("tr");
  for (var i = 0; i < month_rows.length; i++) {
    var month_row = $(month_rows[i]);
    var months = month_row.data("months");
    var extra_months = 0;
    if (plus_rent) {
      extra_months = 6;
    }
    var min_amount = parseInt(month_row.data("min"), 10);
    if (isNaN(min_amount)) {
      min_amount = 0;
    }
    var max_amount = parseInt(month_row.data("max"), 10);
    if (isNaN(max_amount)) {
      max_amount = 0;
    }
    if (doInterest) {
      interest = parseFloat(month_row.data("apr")) / 100 / 12;
    }
    var payment = $("#payment-calculator-modal-months" + type + "-" + months);
    if (
      (min_amount !== 0 && min_amount > principal) ||
      (max_amount !== 0 && max_amount < principal)
    ) {
      payment.html("N/A");
    } else {
      payment.html(
        "$" +
          numeral(calculatePayment(principal, interest, months)).format(
            "0,0.00",
          ),
      );
    }
  }
};

window.calculate = calculate;

const settingCookieAndGA = () => {
  function gtag() {
    dataLayer.push(arguments);
  }
  const currentENV = process.env.ENVIRONMENT;
  if (currentENV === "PROD" || currentENV === "TEST") {
    const { GOOGLE_ANALYTICS_ID, COOKIE_ID } = process.env;
    const analyticScript = document.createElement("script");
    analyticScript.src = "https://www.googletagmanager.com/gtag/js";
    analyticScript.async = true;
    document.body.appendChild(analyticScript);
    window.dataLayer = window.dataLayer || [];

    gtag("js", new Date());
    gtag("config", GOOGLE_ANALYTICS_ID);

    const cookieScript = document.createElement("script");
    cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    cookieScript.type = "text/javascript";
    cookieScript.setAttribute("data-domain-script", COOKIE_ID);
    document.body.appendChild(cookieScript);
  }
};

settingCookieAndGA();
